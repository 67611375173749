import React from "react";
import { Link } from "gatsby";
import { resizeAndConvertImage } from "../../utils/common";

export default ({ data }) => {
  return (
    <React.Fragment>
      <Link
        to={`/case-study/${data.slug}`}
        className="shadow-box videos w-inline-block"
      >
        <div className="video-container">
          {data.featuredImage && <img
            src={`${data.featuredImage.url}${resizeAndConvertImage(600)}`}
            alt={data.featuredImage.alt}
            width="350"
            className="video-thumb"
          />}
        </div>
        <div className="platform-detail videos">
          <div className="small-copy">{data.publishedOn}</div>
          <div className="tn-spacing" />
          <div className="blog-title-heading">
            <h6 className="h6">{data.pageTitle}</h6>
          </div>
          <div className="sm-spacing" />
        </div>
      </Link>
    </React.Fragment>
  );
};
