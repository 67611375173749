import React from "react";
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";
import { resetWebflow, replaceContentWithImgSize } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import SocialShare from "../components/common/social-share";
import Notification from "../components/header/notification";
import CaseStudyPreview from "../components/case-study/caseStudyPreview";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsCaseStudyDetail: {
      pageTitle,
      content,
      seoMetaTags,
      notificationPage
    },
    allDatoCmsNotification: { edges: notifications },
    relatedCaseStudies: { edges: caseStudies }
  }
}) => {
  var replaceImgContent = replaceContentWithImgSize(content);
  const previewCaseStudies = caseStudies.map(({ node }) => {
    return <CaseStudyPreview data={node} />;
  });

  resetWebflow("5ba525b1e783e2b20a301a07");

  return (
    <Layout>
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <HelmetDatoCms seo={seoMetaTags} />
      <header className="main-header about">
        <div className="container grid">
          <SocialShare id={"6b76be8b-c8b9-f4b2-4c1c-1e1ccff08bf5"} />
          <div
            data-w-id="1cdb0609-5797-4ec2-2433-4b7250f7e631"
            className="post-container"
          >
            <h1>{pageTitle}</h1>
            <ReactMarkdown source={replaceImgContent} escapeHtml={false} />
            <div className="sm-spacing _30" />
            <div className="divider" />
          </div>
        </div>
        <div className="lg-spacing" />
        <div className="container">
          <div className="grid-container-3">
            {previewCaseStudies}
          </div>
        </div>
      </header>
    </Layout>
  );
};

export const query = graphql`
  query CaseStudyDetailQuery($slug: String!) {
    datoCmsCaseStudyDetail(slug: { eq: $slug }) {
      id
      pageTitle
      publishedOn(formatString: "MMM D, YYYY H:mm A")
      featuredImage {
        url
      }
      content
      notificationPage {
        id
        page
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    relatedCaseStudies: allDatoCmsCaseStudyDetail(filter: {slug: {ne: $slug}}, limit: 3) {
      edges {
        node {
          id
          slug
          pageTitle
          publishedOn(formatString: "MMM D, YYYY H:mm A")
          featuredImage {
            url
          }
          content
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
